



import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
  const [rooms, setRooms] = useState([]);
  const [adults, setAdults] = useState('1 Adult');
  const [kids, setKids] = useState('0 Kids');
  const [totalGuests, setTotalGuests] = useState(0);
  const [loading, setLoading] = useState(false);

  const [selectedRoomDetails, setSelectedRoomDetails] = useState({
    checkInDate: '',
    checkOutDate: '',
    roomType: '',
    adults: 1,
    kids: 0,
    price: 0,
    mealPlan: null, // Added meal plan
    mealCost: 0, // Added meal cost
    taxes: 0,
    totalAmount: 0,
    numberOfDays: 1, // Default to 1 day
  });

  // Fetch room data from the backend API
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get('http://localhost:5001/v1/api/rooms');
        setRooms(response.data);
      } catch (error) {
        console.error('Error fetching room data:', error);
      }
    };
    fetchRooms();
  }, []);

  // Update total number of guests when adults or kids change
  useEffect(() => {
    const numberOfAdults = parseInt(adults.split(' ')[0], 10);
    const numberOfKids = parseInt(kids.split(' ')[0], 10);
    setTotalGuests(numberOfAdults + numberOfKids);
  }, [adults, kids]);

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    const checkInDate =
      document.querySelector('#checkIn').value ||
      new Date().toISOString().split('T')[0];
    const checkOutDate =
      document.querySelector('#checkOut').value ||
      (() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow.toISOString().split('T')[0];
      })();

    const adultsCount = parseInt(adults.split(' ')[0], 10);
    const kidsCount = parseInt(kids.split(' ')[0], 10);

    // Validate check-in and check-out dates
    const checkIn = new Date(checkInDate);
    const checkOut = new Date(checkOutDate);

    if (checkOut <= checkIn) {
      alert('Check-out date must be later than check-in date.');
      setLoading(false);
      return;
    }

    const timeDiff = Math.abs(checkOut.getTime() - checkIn.getTime());
    const numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Update the price based on the number of days
    const updatedRooms = rooms.map((room) => {
      const newPrice = room.price * numberOfDays;
      return { ...room, totalAmount: newPrice };
    });

    setRooms(updatedRooms);

    setSelectedRoomDetails((prevDetails) => ({
      ...prevDetails,
      checkInDate,
      checkOutDate,
      adults: adultsCount,
      kids: kidsCount,
      numberOfDays,
    }));

    const filteredRooms = updatedRooms.filter(
      (room) => totalGuests <= room.maxPerson
    );

    // Simulate loading delay and update rooms
    setTimeout(() => {
      setRooms(filteredRooms);
      setLoading(false);
    }, 3000);
  };

  // Update selected room details with new properties
  const updateRoomDetails = (details) => {
    setSelectedRoomDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
    }));
  };

  // Set default check-in and check-out dates for initialization
  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    setSelectedRoomDetails((prevDetails) => ({
      ...prevDetails,
      checkInDate: today.toISOString().split('T')[0],
      checkOutDate: tomorrow.toISOString().split('T')[0],
    }));
  }, []);

  return (
    <RoomContext.Provider
      value={{
        rooms,
        setRooms,
        adults,
        setAdults,
        kids,
        setKids,
        totalGuests,
        handleClick,
        loading,
        selectedRoomDetails,
        setSelectedRoomDetails,
        updateRoomDetails,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};
