import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RoomContext } from '../context/RoomContext';
import ScrollToTop from '../components/ScrollToTop';
import { FaMapMarkerAlt, FaStar,FaWifi, FaCoffee, FaParking, FaUtensils, FaGlassCheers } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import Reviews from '../pages/Reviews';

const RoomDetails = () => {
  const { rooms, selectedRoomDetails, setSelectedRoomDetails } = useContext(RoomContext);
  const { id } = useParams();
  const navigate = useNavigate();
  
  const room = rooms.find((room) => room.id === Number(id));
  const [mealPlan, setMealPlan] = useState(null);
  const [showMealOptions, setShowMealOptions] = useState(false);

  if (!room) {
    return <div>Room not found</div>;
  }
         
  const handleMealToggle = () => {
    setShowMealOptions(!showMealOptions);
  };

  const handleMealPlanChange = (plan) => {
    // If the selected plan is already active, toggle it off by setting it to null
    if (mealPlan === plan) {
      setMealPlan(null);
      setSelectedRoomDetails((prevDetails) => ({
        ...prevDetails,
        mealPlan: null,
        mealCost: 0,
      }));
    } else {
      // Otherwise, set the selected meal plan and calculate the cost
      setMealPlan(plan);
      const mealCost = plan === 'CP' ? 300 : plan === 'EP' ? 200 : 0;
      setSelectedRoomDetails((prevDetails) => ({
        ...prevDetails,
        mealPlan: plan,
        mealCost: mealCost * ((selectedRoomDetails.adults || 0) + (selectedRoomDetails.kids || 0)) * (selectedRoomDetails.numberOfDays || 1),
      }));
    }
  };
  
  const calculateMealCost = () => {
    const numberOfDays = selectedRoomDetails.numberOfDays || 1;
    const totalPeople = (selectedRoomDetails.adults || 0) + (selectedRoomDetails.kids || 0);
    const mealCost = mealPlan === 'CP' ? 200 : mealPlan === 'EP' ? 0 : 0;
    return mealCost * totalPeople * numberOfDays;
  };

  const handleNavigation = () => {
    const numberOfDays = selectedRoomDetails.numberOfDays || 1;
    //const totalPeople = (selectedRoomDetails.adults || 0) + (selectedRoomDetails.kids || 0);
    const mealCost = calculateMealCost();
    const priceWithMeal = room.price * numberOfDays + mealCost;
    const taxes = Math.round(priceWithMeal * 0.12); // Assuming 12% tax rate
    const totalAmount = priceWithMeal + taxes;

    setSelectedRoomDetails((prevDetails) => ({
      ...prevDetails,
      roomType: room.name,
      price: priceWithMeal,
      mealPlan,
      mealCost,
      taxes,
      totalAmount,
      adults: selectedRoomDetails.adults,
      kids: selectedRoomDetails.kids,
    }));
    navigate('/customerdetails');
  };

  return (
    <article>
      <Helmet>
        <title>{room.name} | Elite Hotel by Signature</title>
        <meta name="description" content={`Details and booking for ${room.name}. Enjoy luxury and comfort with our top-notch amenities.`} />
      </Helmet>
      <ScrollToTop />
      <header className="bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center">
        <div className="absolute w-full h-full bg-black/70"></div>
        <h1 className="text-4xl md:text-6xl text-white z-20 font-primary text-center">
          {room.name}
        </h1>
      </header>
      <section className="container mx-auto px-4 md:px-0">
        <div className="flex flex-col lg:flex-row h-full py-12 lg:py-24">
          <div className="w-full lg:w-[60%] px-4 md:px-6">
            <h2 className="h2 text-3xl md:text-4xl">{room.name}</h2>
            <p className="mb-8 text-lg">{room.description}</p>  
            {/* <img className="mb-8 w-full rounded shadow-lg" src={room.imageLg} alt={room.name} /> */}
            <section>
              <h3 className="h3 text-2xl mt-8">Hotel Rules</h3>
              <ul className="mb-6 list-disc list-inside text-lg">
                <li>Quiet hours are observed from 10 PM to 7 AM</li>
                <li>Visitors must depart by 10 PM.</li>
                <li>Please maintain the room in good condition</li>
                <li>Use the in-room safe for storing valuables</li>
                <li>Contact the front desk in case of emergencies</li>
              </ul>
            </section>
            <section>
              <h3 className="h3 text-2xl mt-8">Room Facilities</h3>
              <ul className="mb-6 list-none text-lg">
                <li className="flex items-center">
                  <FaWifi className="text-blue-500 mr-2" /> Wifi
                </li>
                <li className="flex items-center">
                  <FaCoffee className="text-yellow-500 mr-2" /> Coffee
                </li>
                <li className="flex items-center">
                  <FaParking className="text-green-500 mr-2" /> Parking Space
                </li>
                <li className="flex items-center">
                  <FaUtensils className="text-orange-500 mr-2" /> Breakfast
                </li>
                <li className="flex items-center">
                  <FaGlassCheers className="text-purple-500 mr-2" /> Drinks
                </li>
              </ul>
            </section>
          </div>
          <aside className="w-full lg:w-[40%] px-4 md:px-6">
            <ReservationSummary
              room={room}
              handleNavigation={handleNavigation}
              mealPlan={mealPlan}
              handleMealPlanChange={handleMealPlanChange}
              calculateMealCost={calculateMealCost}
              showMealOptions={showMealOptions}
              handleMealToggle={handleMealToggle}
            />
          </aside>
          
        </div>
        
       </section>
       <section className="bg-gray-10 mb-10">
        <div className="container mx-auto px-4">
         
           <Reviews />
        </div>
       </section>

    </article>
  );
};

const ReservationSummary = ({ room, handleNavigation, mealPlan, handleMealPlanChange, calculateMealCost, showMealOptions, handleMealToggle }) => {
  const { selectedRoomDetails } = useContext(RoomContext);

  return (
    <div className="p-4 border rounded shadow-lg bg-white">
      <h3 className="text-xl font-semibold">{room.name}</h3>
      <div className="my-4">
        <div className="grid grid-cols-2 gap-2">
          <p><strong>Check-In:</strong> {selectedRoomDetails.checkInDate || "N/A"}</p>
          <p><strong>Check-Out:</strong> {selectedRoomDetails.checkOutDate || "N/A"}</p>
          <p><strong>Adults:</strong> {selectedRoomDetails.adults}</p>
          <p><strong>Kids:</strong> {selectedRoomDetails.kids}</p>
        </div>
      </div>

      {/* Add Meal Toggle Button */}
      <button
        className={`flex items-center justify-center px-4 py-2 mb-2 rounded border w-full ${showMealOptions ? 'text-blue-500 border-blue-500' : 'text-gray-700 border-gray-300'}`}
        onClick={handleMealToggle}
      >
        <FaStar className={`mr-2 ${showMealOptions ? 'text-blue-500' : 'text-gray-700'}`} />
        Add Meal
      </button>

      {/* Meal Plan Options: CP and EP */}
      {showMealOptions && (
        <div className="flex gap-4 mb-4">
          <button
            className={`flex items-center justify-center px-4 py-2 rounded border ${mealPlan === 'CP' ? 'text-blue-500 border-blue-500' : 'text-gray-700 border-gray-300'}`}
            onClick={() => handleMealPlanChange('CP')}
          >
            CP (breakfast)
          </button>
          <button
            className={`flex items-center justify-center px-4 py-2 rounded border ${mealPlan === 'EP' ? 'text-blue-500 border-blue-500' : 'text-gray-700 border-gray-300'}`}
            onClick={() => handleMealPlanChange('EP')}
          >
            EP (without-breakfast)
          </button>
        </div>
      )}

      <ul className="list-disc pl-5">
        <li className="text-green-600">Free Cancellation till check-in</li>
        <li className="text-blue-500">Couples are allowed at our Property</li>
      </ul>

      <p className="text-2xl font-bold">
        ₹ {room.price * selectedRoomDetails.numberOfDays + calculateMealCost()} + ₹ {Math.round((room.price * selectedRoomDetails.numberOfDays + calculateMealCost()) * 0.12)} taxes & fees
      </p>
      
      <button
        className="btn btn-lg btn-primary w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
        onClick={handleNavigation}
      >
        BOOK THIS NOW
      </button>
      
      <div className="mt-4 border-t pt-4">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full">
            <p className="text-lg font-bold text-blue-500">3.9</p>
          </div>
          <div className="ml-4">
            <p className="font-bold text-blue-700">Very Good</p>
          </div>
        </div>
        
        <div className="flex items-center mt-4">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full">
            <FaMapMarkerAlt className="text-blue-500" />
          </div>
          <div className="ml-4">
            <a
              href="https://www.google.com/maps/place/Hotel+Elite+By+Signature+Group"
              className="flex items-center"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="View on Google Maps"
            >
              <span> 11-28/21, Rajiv Gandhi Airport Road, Shamshabad, Hyderabad, Telangana 501218</span>
            </a>
            <p className="text-sm text-blue-500 cursor-pointer">
              <a
                href="https://www.google.com/maps/place/Hotel+Elite+By+Signature+Group"
                target="_blank"
                rel="noopener noreferrer"
              >
                See on Map
              </a>
            </p>
          </div>
        </div>
      </div>
     
    </div>
    
  
  );
};

export default RoomDetails;


