

import React from 'react';
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaInstagram,
  FaFacebook,
  FaYoutube,
 
} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import LogoOak from '../assets/img/elite-logo.png';

const Footer = () => {
  const navigate = useNavigate();

  const handleBookNowClick = () => {
    navigate('/rooms');
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <footer
      className="bg-primary py-12 relative text-lg bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${require('../assets/img/footer2.png')})` }}
    >
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 text-white">
        {/* Logo and Social Media Section */}
        <div className="flex flex-col items-center lg:items-start">
          <div className="flex flex-col lg:flex-row items-center lg:items-start">
            {/* Logo */}
            <a href="/" className="mb-4 flex items-center lg:mb-0">
              <img
                src={LogoOak}
                alt="Logo"
                className="w-32 sm:w-40 lg:w-48 transition-all duration-300 "
              />
            </a>
            {/* Social Media Icons */}
            <div className="flex items-center space-x-4 mt-4 lg:mt-0 lg:ml-8">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl lg:text-3xl hover:scale-110 transition-transform duration-300 hover:text-red-500"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl lg:text-3xl hover:scale-110 transition-transform duration-300 hover:text-blue-500"
                aria-label="Facebook"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl lg:text-3xl hover:scale-110 transition-transform duration-300 hover:text-red-600"
                aria-label="YouTube"
              >
                <FaYoutube />
              </a>
              
            </div>
          </div>
          {/* Address and Contact Information */}
          <div className="text-center lg:text-left mt-4">
            <a
              href="https://www.google.com/maps/place/Hotel+Elite+By+Signature+Group/@17.2605671,78.389139,15z/data=!4m9!3m8!1s0x3bcbbd0672b8a981:0x8deb7e3b2242b942!5m2!4m1!1i2!8m2!3d17.2605671!4d78.389139!16s%2Fg%2F11rvc73sk_?entry=ttu&g_ep=EgoyMDI0MDgyMS4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:text-gray-300 mb-2"
            >
              <FaMapMarkerAlt className="mr-2 text-xl" />
              <span>
                11-28/21, Rajiv Gandhi Airport Road, Shamshabad, Hyderabad,
                Telangana 501218
              </span>
            </a>
            <div className="flex items-center hover:text-gray-300 mb-2">
              <FaPhone className="mr-2 text-xl" />
              <span>+91 6309387321</span>
            </div>
            <div className="flex items-center hover:text-gray-300">
              <FaEnvelope className="mr-2 text-xl" />
              <a
                href="mailto:info.hiwahotels@gmail.com"
                className="hover:underline"
              >
                info.hiwahotels@gmail.com
              </a>
            </div>
          </div>
          <button
              onClick={handleBookNowClick}
             className="text-black mt-8 py-2 px-6 rounded-full transition duration-300"
                style={{
               backgroundColor: "#FFD700", // Pure golden color
             }}
              >
            Book Now
            </button>

        </div>

        {/* Services Section */}
        <div>
          <h4 className="font-bold border-b-2 border-red-500 pb-2 text-xl mb-4">
            OUR SERVICES
          </h4>
          <ul className="list-disc pl-4 space-y-2">
            <li>Luxury Rooms</li>
            <li>
              <Link to="/restaurant" className="hover:underline">
                Restaurant
              </Link>
            </li>
            <li>Banquet Hall</li>
            <li>Conference</li>
            <li>Board Room</li>
          </ul>
        </div>

        {/* Restaurant Section */}
        <div>
          <h4 className="font-bold border-b-2 border-red-500 pb-2 text-xl mb-4">
            OUR RESTAURANT
          </h4>
          <ul className="list-disc pl-4 space-y-2">
            <li>Indian</li>
            <li>Chinese</li>
            <li>Tandoor</li>
            <li>Special Biryani</li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="container mx-auto mt-8 flex flex-col lg:flex-row items-center justify-between">
        <div className="text-center text-white lg:text-left">
          <p>
            © Elite by Hiwa Hotels, Shamshabad Airport Zone 2024. All rights
            reserved.
          </p>
          <button
            onClick={() => handleNavigation('/login')}
            className="mt-2 text-red-500 hover:text-red-600"
          >
            .
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

