//Import React and necessary icons
import React, { useState,useEffect   } from 'react';
import axios from 'axios'; // For API requests
import {
  FaWifi,
  FaCoffee,
  FaBath,
  FaParking,
  FaHotdog,
  FaCocktail,
  //FaChevronUp,
 // FaChevronDown,
  FaArrowsAlt,
  FaUser,
} from 'react-icons/fa';


// Import images
import Room1Img from '../assets/img/rooms/1.png';
import Room1ImgLg from '../assets/img/rooms/1-lg.png';
import Room2Img from '../assets/img/rooms/2.png';
import Room2ImgLg from '../assets/img/rooms/2-lg.png';
import Room3Img from '../assets/img/rooms/3.png';
import Room3ImgLg from '../assets/img/rooms/3-lg.png';
import Room4Img from '../assets/img/rooms/4.png';
import Room4ImgLg from '../assets/img/rooms/4-lg.png';
import Room6Img from '../assets/img/rooms/6.png';
import Room6ImgLg from '../assets/img/rooms/6-lg.png';
import Room7Img from '../assets/img/rooms/7.png';
import Room7ImgLg from '../assets/img/rooms/7-lg.png';
import Room8Img from '../assets/img/rooms/8.png';
import Room8ImgLg from '../assets/img/rooms/8-lg.png';
import Room9Img from '../assets/img/rooms/9.png';
import Room9ImgLg from '../assets/img/rooms/9-lg.png';

// Hardcoded room data with actual images
export const roomData = [
  {
    id: 1,
    name: 'Superior Room',
    description:
      'Indulge in the refined elegance of our Superior Room , offering 400 square feet of meticulously designed space. Featuring a king-sized bed, a comfortable seating area, and modern amenities like free Wi-Fi and a flat-screen TV, this room ensures a relaxing and enjoyable stay.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      
     { name: 'Breakfast', icon: <FaHotdog /> },
     
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 180,
    maxPerson: 1,
    price: 1999,
    image: Room1Img,
    imageLg: Room1ImgLg,
  },
  {
    id: 2,
    name: 'Superior Room',
    description:
      'Indulge in the refined elegance of our Superior Room, offering 400 square feet of meticulously designed space. Featuring a king-sized bed, a comfortable seating area, and modern amenities like free Wi-Fi and a flat-screen TV, this room ensures a relaxing and enjoyable stay.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      
      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 180,
    maxPerson: 2,
    price:2220,
    image: Room2Img,
    imageLg: Room2ImgLg,
  },
  {
    id: 3,
    name: 'Superior Room',
    description:
      'Indulge in the refined elegance of our Superior Room, offering 400 square feet of meticulously designed space. Featuring a king-sized bed, a comfortable seating area, and modern amenities like free Wi-Fi and a flat-screen TV, this room ensures a relaxing and enjoyable stay.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
     
      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 180,
    maxPerson: 3,
    price: 2865,
    image: Room3Img,
    imageLg: Room3ImgLg,
  },
  {
    id: 4,
    name: 'Deluxe Room',
    description:
      'Experience comfort in our Queen Room, featuring 400 square feet of well-appointed space. Enjoy a restful night on a plush queen-sized bed, stay connected with free Wi-Fi, and unwind with a flat-screen TV. The room also includes a cozy seating area and a modern ensuite bathroom, perfect for both relaxation and productivity.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      
      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 200,
    maxPerson: 1,
    price: 2000,
    image: Room4Img,
    imageLg: Room4ImgLg,
  },
  {
    id: 5,
    name: 'Deluxe Room',
    description:
      'Experience comfort in our Queen Room, featuring 300 square feet of well-appointed space. Enjoy a restful night on a plush queen-sized bed, stay connected with free Wi-Fi, and unwind with a flat-screen TV. The room also includes a cozy seating area and a modern ensuite bathroom, perfect for both relaxation and productivity.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },

      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 200,
    maxPerson: 2,
    price: 2320,
    image: Room4Img,
    imageLg: Room4ImgLg,
  },
  {
    id: 6,
    name: 'Deluxe Room',
    description:
      'Experience comfort in our Queen Room, featuring 300 square feet of well-appointed space. Enjoy a restful night on a plush queen-sized bed, stay connected with free Wi-Fi, and unwind with a flat-screen TV. The room also includes a cozy seating area and a modern ensuite bathroom, perfect for both relaxation and productivity.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
     
      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 200,
    maxPerson: 3,
    price: 3144,
    image: Room6Img,
    imageLg: Room6ImgLg,
  },
  {
    id: 7,
    name: 'Suit Room',
    description:
      'Discover luxury in our Suite Room, offering 500 square feet of elegantly designed space. Featuring a separate living area, a king-sized bed, a kitchenette, free Wi-Fi, and a flat-screen TV, this suite ensures the ultimate in comfort and sophistication.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },

      { name: 'Breakfast', icon: <FaHotdog /> },
     
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 220,
    maxPerson: 1,
    price: 3500,
    image: Room7Img,
    imageLg: Room7ImgLg,
  },
  {
    id: 8,
    name: 'Suit Room',
    description:
      'Discover luxury in our Suite Room, offering 500 square feet of elegantly designed space. Featuring a separate living area, a king-sized bed, a kitchenette, free Wi-Fi, and a flat-screen TV, this suite ensures the ultimate in comfort and sophistication.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },

      { name: 'Breakfast', icon: <FaHotdog /> },
     
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 220,
    maxPerson: 2,
    price: 3700,
    image: Room8Img,
    imageLg: Room8ImgLg,
  },
  {
    id: 9,
    name: 'Suit Room',
    description:
      'Discover luxury in our Suite Room, offering 500 square feet of elegantly designed space. Featuring a separate living area, a king-sized bed, a kitchenette, free Wi-Fi, and a flat-screen TV, this suite ensures the ultimate in comfort and sophistication.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },

      { name: 'Breakfast', icon: <FaHotdog /> },
      
      { name: 'Drinks', icon: <FaCocktail /> },
    ],
    size: 220,
    maxPerson: 3,
    price: 4000,
    image: Room9Img,
    imageLg: Room9ImgLg,
  },
  
];

const Rates = () => {
  const [rooms, setRooms] = useState(roomData);
 // const [expandedRoomId, setExpandedRoomId] = useState(null);
  const [editedRooms, setEditedRooms] = useState({});

  // Fetch room data from API
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get('http://localhost:5001/v1/api/rooms');
        setRooms(response.data);
      } catch (error) { 
        console.error('Error fetching room data:', error);
        alert('Error fetching room data. Please check the console for details.');
      }
    
    };
    fetchRooms();
  }, []);

  

  const handleInputChange = (roomId, field, value) => {
    setEditedRooms((prev) => ({
      ...prev,
      [roomId]: { ...prev[roomId], [field]: value },
    }));
  };

  const saveChanges = async (roomId) => {
    const updatedPrice = editedRooms[roomId]?.price;

    if (updatedPrice) {
      try {
        const response = await axios.put(`http://localhost:5001/v1/api/rooms/${roomId}/price`, {
          price: updatedPrice,
        });

        if (response.status === 200) {
          alert('Price updated successfully');
          setRooms((prevRooms) =>
            prevRooms.map((room) =>
              room.id === roomId ? { ...room, price: updatedPrice } : room
            )
          );
          setEditedRooms((prev) => ({ ...prev, [roomId]: '' }));
        } else {
          alert('Failed to update price');
        }
      } catch (error) {
        console.error('Error updating price:', error);
        alert(`Failed to update price: ${error.response?.data?.message || error.message}`);
      }
    }
  };
  return (
    <div className="p-6 bg-gray-100 min-h-screen text-gray-800">
      <div className="text-center mb-6">
        <h1 className="text-2xl font-bold text-gray-700">Manage Room Rates</h1>
        <p className="text-gray-500 mt-1">Adjust rates for each room category and max-person capacity.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rooms.map((room) => (
          <div key={room.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            {/* Image Path Correctly Prefixed */}
            <img src={`http://localhost:5001${room.image}`} alt={`${room.name}`} className="w-full h-32 object-cover" />
            <div className="p-3">
              <div className="flex items-center justify-between">
                <h2 className="text-md font-semibold text-gray-700">{room.name}</h2>
              </div>
              <div className="text-gray-500 text-xs mb-2">{room.description}</div>
              <div className="flex items-center justify-between text-gray-600 text-xs mb-2">
                <div className="flex items-center">
                  <FaArrowsAlt className="mr-1" /> Size: {room.size} SF
                </div>
                <div className="flex items-center">
                  <FaUser className="mr-1" /> Max People: {room.maxPerson}
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <input
                  type="number"
                  value={editedRooms[room.id]?.price ?? room.price}
                  onChange={(e) => handleInputChange(room.id, 'price', e.target.value)}
                  className="w-2/3 p-1 bg-gray-100 border border-gray-400 rounded-l-md text-gray-800 text-center"
                />
                <button
                  onClick={() => saveChanges(room.id)}
                  className="w-1/3 bg-red-500 hover:bg-red-600 text-white py-1 rounded-r-md"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rates;




