import React, { createContext, useState, useContext } from "react";

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState({
    revenue: 0,
    pendingAmount: 0,
    checkIns: 0,
  });

  const incrementRevenue = (amount) => {
    setDashboardData((prev) => ({
      ...prev,
      revenue: prev.revenue + amount,
    }));
  };

  const incrementPendingAmount = (amount) => {
    setDashboardData((prev) => ({
      ...prev,
      pendingAmount: prev.pendingAmount + amount,
    }));
  };

  const movePendingToRevenue = (amount) => {
    setDashboardData((prev) => ({
      ...prev,
      revenue: prev.revenue + amount,
      pendingAmount: prev.pendingAmount - amount,
    }));
  };

  const incrementCheckIns = () => {
    setDashboardData((prev) => ({
      ...prev,
      checkIns: prev.checkIns + 1,
    }));
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        incrementRevenue,
        incrementPendingAmount,
        movePendingToRevenue,
        incrementCheckIns,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

// Custom Hook for easier access
export const useDashboard = () => useContext(DashboardContext);
