


import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('http://localhost:5001/v1/api/dashboard');
        console.log('API Response:', response.data); // Debug log
        setDashboardData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <div className="p-8 bg-gradient-to-b from-gray-800 to-gray-900 text-white min-h-screen flex items-center justify-center">
        <div className="text-2xl font-bold animate-pulse">Loading Dashboard...</div>
      </div>
    );
  }

  if (!dashboardData || Object.keys(dashboardData).length === 0) {
    return <div>No data available.</div>;
  }

  const today = new Date();
  const days = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() - (6 - i));
    return date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
  });

  const revenueBarChartData = {
    labels: days,
    datasets: [
      {
        label: 'Revenue',
        data: dashboardData.last7DaysRevenue || [], // Use dynamic revenue data
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
      },
    ],
  };

  const revenueBarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `₹ ${context.raw.toLocaleString()}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Weekdays',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Amount (₹)',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
          callback: (value) => `₹ ${value}`,
        },
        beginAtZero: true,
      },
    },
  };

  const doughnutChartData = {
    labels: ['Pending Amount', 'Total Revenue'],
    datasets: [
      {
        data: [dashboardData.pendingAmount, dashboardData.totalRevenue],
        backgroundColor: ['#FF9F40', '#4BC0C0'],
        hoverBackgroundColor: ['#FFA963', '#5CD8D8'],
        borderWidth: 2,
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `₹ ${context.raw.toLocaleString()}`,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className="p-8 bg-gradient-to-b from-gray-800 to-gray-900 text-white min-h-screen">
      <div className="text-4xl font-extrabold text-white mb-6">Performance Overview</div>

      {/* Metrics Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-gradient-to-r from-teal-500 to-blue-600 p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
          <div className="text-lg font-medium text-gray-100">Today's Revenue</div>
          <div className="text-4xl font-bold text-white mt-2">₹ {dashboardData.todaysRevenue.toLocaleString()}</div>
          <div className="text-sm text-gray-200 mt-4">
            Total Revenue Till Now: ₹ {dashboardData.totalRevenue.toLocaleString()}
          </div>
        </div>

        <div className="bg-gradient-to-r from-yellow-400 to-orange-500 p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
          <div className="text-lg font-medium text-gray-100">Pending Amount</div>
          <div className="text-4xl font-bold text-white mt-2">₹ {dashboardData.pendingAmount.toLocaleString()}</div>
        </div>

        <div className="bg-gradient-to-r from-purple-500 to-pink-600 p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
          <div className="text-lg font-medium text-gray-100">Today's Check-ins</div>
          <div className="text-4xl font-bold text-white mt-2">{dashboardData.todaysCheckIns}</div>
          <div className="text-sm text-gray-200 mt-4">
            FOR LAST 7 DAYS: {dashboardData.checkInsLast7Days} CHECK-INS
          </div>
        </div>
      </div>

      {/* Revenue and Doughnut Graphs */}
      <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Bar Chart */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg">
          <div className="text-lg font-medium text-gray-300 mb-4">Revenue Graph</div>
          <div className="h-80">
            <Bar data={revenueBarChartData} options={revenueBarChartOptions} />
          </div>
        </div>

        {/* Doughnut Chart */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg">
          <div className="text-lg font-medium text-gray-300 mb-4">Pending vs Total Revenue</div>
          <div className="h-80 flex justify-center items-center">
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
