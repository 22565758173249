import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { EffectFade, Autoplay, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import menuImage from "../assets/img/menu.png";
import Img1 from "../assets/img/restaSlider/4.png";
import Img2 from "../assets/img/restaSlider/2.png";
import Img3 from "../assets/img/restaSlider/3.jpg";
import BackgroundImage from "../assets/img/bg.png";

const images = [Img1, Img2, Img3];

const Restaurant = () => {
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [selectedMenuImage, setSelectedMenuImage] = useState("");

  const navigate = useNavigate();

  const openMenuModal = (image) => {
    setSelectedMenuImage(image);
    setIsMenuModalOpen(true);
  };

  const closeMenuModal = () => {
    setSelectedMenuImage("");
    setIsMenuModalOpen(false);
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeMenuModal();
    }
  };

  const handleCheckNowClick = () => {
    navigate("/rooms");
  };

  return (
    <div className="relative overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></div>
      <div className="relative z-10">
        <Header />
        {/* Main Content */}
        <div className="container mx-auto py-16 lg:py-24 px-6 lg:px-12">
          <div className="flex flex-wrap lg:flex-nowrap items-center gap-12 lg:gap-16">
            {/* Swiper Section */}
            <div className="w-full lg:w-1/2 rounded-lg shadow-lg overflow-hidden">
              <Swiper
                modules={[EffectFade, Autoplay, Navigation]}
                effect="fade"
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                className="h-[300px] lg:h-[500px] rounded-lg"
              >
                {images.map((image, index) => (
                  <SwiperSlide
                    key={index}
                    className="flex justify-center items-center"
                  >
                    <img
                      src={image}
                      alt={`Restaurant Slide ${index + 1}`}
                      className="w-full h-full object-cover cursor-pointer"
                      onClick={() => openMenuModal(image)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* Text Section */}
            <div className="w-full lg:w-1/2 text-white bg-black/70 p-8 rounded-lg shadow-lg">
              <h1 className="text-4xl font-bold mb-6 text-yellow-400">
                About Our Restaurant
              </h1>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                Exceptional Dining Experience
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Welcome to our restaurant! We offer a variety of delicious
                dishes made from the freshest ingredients. Our mission is to
                provide an exceptional dining experience for all our guests.
              </p>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                A Menu for Every Occasion
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Whether you are here for a casual meal or a special occasion, we
                strive to make your visit memorable. Our friendly staff is here
                to assist you with any special requests or dietary needs.
              </p>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                View Our Menu
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Explore the delectable dishes our talented chefs prepare and
                discover the perfect meal for your taste.
              </p>
              {/* Buttons */}
              <div className="flex space-x-4">
                <button
                  onClick={() => openMenuModal(menuImage)}
                  className="text-white bg-yellow-500 py-3 px-6 rounded-md hover:bg-yellow-600 transition-colors font-semibold"
                >
                  View Our Menu
                </button>
                <button
                  onClick={handleCheckNowClick}
                  className="text-white bg-yellow-500 py-3 px-6 rounded-md hover:bg-yellow-600 transition-colors font-semibold"
                >
                  Check Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Menu Modal */}
      {isMenuModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 modal-overlay"
          onClick={handleModalClick}
        >
          <div className="relative max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
            <button
              onClick={closeMenuModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={selectedMenuImage}
              alt="Menu"
              className="w-full h-auto object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Restaurant;
