
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminBg from '../assets/img/admingall/loginbg.png';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState('login'); // Steps: login, otpRequest, verifyOtp
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(30);
  const [otpExpired, setOtpExpired] = useState(false);

  // Timer Effect for OTP expiration
  useEffect(() => {
    if (step === 'verifyOtp' && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval
    }

    if (timer === 0) {
      setOtpExpired(true);
    }
  }, [timer, step]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('http://localhost:5001/v1/api/login', { username, password });
      navigate(response.data.redirect);
    } catch (error) {
      setError(error.response?.data?.message || 'Server error.');
    }
  };

  const handleRequestOTP = async () => {
    try {
      const response = await axios.post('http://localhost:5001/v1/api/login/request-otp');
      setMessage(response.data.message);
      setStep('verifyOtp');
      setTimer(60); // Reset timer for OTP expiration
      setOtpExpired(false);
    } catch (error) {
      setError(error.response?.data?.message || 'Server error.');
    }
  };

  const handleVerifyOtp = async () => {
    if (otpExpired) {
      setError('OTP has expired. Please request a new OTP.');
      return;
    }

    try {
      console.log('Sending payload:', { otp, newUsername, newPassword }); // Debug log
      const response = await axios.post('http://localhost:5001/v1/api/login/verify-update', {
        otp,
        newUsername,
        newPassword,
      });
      alert(response.data.message);
      setStep('login');
    } catch (error) {
      setError(error.response?.data?.message || 'Server error.');
    }
  };

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleNewPasswordVisibility = () => setNewPasswordVisible(!newPasswordVisible);

  return (
    <div
      className="relative h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${AdminBg})`, filter: 'brightness(0.7)' }}
    >
      {step === 'login' && (
        <div className="w-full max-w-lg mx-auto bg-gradient-to-b from-black to-gray-900 rounded-lg shadow-xl overflow-hidden">
          <form onSubmit={handleLoginSubmit} className="p-6">
            <h1 className="text-3xl font-bold text-center text-blue-500 mb-6">Login</h1>
            <div className="mb-4 flex items-center bg-gray-800 p-3 rounded-lg">
              <FaEnvelope className="text-blue-500 mr-2" />
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full bg-transparent text-white outline-none"
                required
              />
            </div>
            <div className="mb-4 flex items-center bg-gray-800 p-3 rounded-lg relative">
              <FaLock className="text-blue-500 mr-2" />
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-transparent text-white outline-none"
                required
              />
              <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 text-blue-500">
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg">
              Login
            </button>
            <button
              type="button"
              onClick={() => setStep('otpRequest')}
              className="w-full text-blue-500 mt-4 hover:underline text-center"
            >
              Forgot Username/Password?
            </button>
          </form>
        </div>
      )}

      {step === 'otpRequest' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-900 p-6 rounded-lg w-96">
            <h2 className="text-2xl font-bold text-white mb-4 text-center">Request OTP</h2>
            <button
              onClick={handleRequestOTP}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg"
            >
              Send OTP
            </button>
            <button
              type="button"
              onClick={() => setStep('login')}
              className="w-full text-red-500 mt-2 hover:underline text-center"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {step === 'verifyOtp' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-900 p-6 rounded-lg w-96">
            <h2 className="text-2xl font-bold text-white mb-4 text-center">Verify OTP & Update</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleVerifyOtp();
              }}
            >
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full mb-4 p-3 bg-gray-700 text-white border border-gray-500 rounded"
                required
              />
              <input
                type="text"
                placeholder="New Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                className="w-full mb-4 p-3 bg-gray-700 text-white border border-gray-500 rounded"
                required
              />
              <div className="relative">
                <input
                  type={newPasswordVisible ? 'text' : 'password'}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full mb-4 p-3 bg-gray-700 text-white border border-gray-500 rounded"
                  required
                />
                <button
                  type="button"
                  onClick={toggleNewPasswordVisibility}
                  className="absolute right-3 mt-4 text-blue-500"
                >
                  {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {otpExpired && <p className="text-red-500 text-center mb-4">OTP has expired. Please request a new OTP.</p>}
              <div className="text-center text-white mb-4">
                {timer > 0 && <p>OTP will expire in {timer} seconds</p>}
              </div>
              <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg">
                Verify & Update
              </button>
              <button
                type="button"
                onClick={handleRequestOTP}
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-white mt-2 py-2 rounded-lg"
              >
                Resend OTP
              </button>
            </form>
          </div>
        </div>
      )}

      {message && <p className="text-green-500 text-center mt-4">{message}</p>}
      {error && <p className="text-red-500 text-center mt-4">{error}</p>}
    </div>
  );
};

export default Login;



