import React, { useState } from 'react';

import hotelLogo from '../assets/img/elite-logo.png';
import AdminHeader from '../components/AdminHeader';
import Dashboard from '../pages/AdminDashboard';
import Bookings from '../pages/AdminBookings';
import Rates from '../pages/AdminRates';
import Inventory from '../pages/AdminInventory';


const Home = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <nav className="w-64 min-w-[16rem] bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white p-6 shadow-lg h-full">
        {/* Logo */}
        <div className="mb-8 flex flex-col items-center justify-center">
          <img 
            src={hotelLogo} 
            alt="Hotel Logo" 
            className="w-32 h-32 object-cover rounded-full shadow-xl border-4 border-gray-700 transform transition-transform duration-500 hover:scale-110 hover:rotate-3"
          />
          <div className="mt-4 text-lg font-bold text-gray-100 tracking-widest uppercase transition-opacity duration-700 opacity-80 hover:opacity-100 text-center">
            Hotel Elite By Signature
          </div>
        </div>

        {/* Navigation Links */}
        <ul className="space-y-4 text-lg">
          <li>
            <button onClick={() => setActiveTab("dashboard")} className="w-full text-left py-2 px-4 rounded-lg hover:bg-gray-700 transition">
              Dashboard
            </button>
          </li>
          <li>
            <button onClick={() => setActiveTab("bookings")} className="w-full text-left py-2 px-4 rounded-lg hover:bg-gray-700 transition">
              Bookings
            </button>
          </li>
          <li>
            <button onClick={() => setActiveTab("rates")} className="w-full text-left py-2 px-4 rounded-lg hover:bg-gray-700 transition">
              Rates  
            </button>
          </li>
          <li>
            <button onClick={() => setActiveTab("inventory")} className="w-full text-left py-2 px-4 rounded-lg hover:bg-gray-700 transition">
            Inventory
            </button>
          </li>
          
        </ul>
      </nav>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-8 bg-gray-100 overflow-auto">
        <div className="flex items-center justify-between mb-8">
          <AdminHeader />
        </div>

        {/* Performance Overview and Active Tab */}
        <div className="h-full">
          {activeTab === "dashboard" && <Dashboard />}
          {activeTab === "bookings" && <Bookings />}
          {activeTab === "rates" && <Rates />}
          {activeTab === "inventory" && <Inventory />}
         
        </div>
      </div>
    </div>
  );
};

export default Home;
