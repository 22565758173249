


import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PreHeader from "./components/PreHeader";
import Home from "./pages/Home";
import About from "./pages/About";
import Restaurant from "./pages/Restaurant";
import Gallery from "./pages/Gallery";
import RoomDetails from "./pages/RoomDetails";
import Rooms from "./components/Rooms";
import HomeHeader from "./components/HomeHeader";
import Contact from "./components/Contact";
import Reviews from "./pages/Reviews";
import CustomerDetails from "./components/CustomerDetails";
import CustomerReviews from "./components/CustomerReviews";
import PaymentSuccess from "./components/PaymentSuccess";
import Login from "./components/Login";
import AdminHeader from "./components/AdminHeader";
import AdminHome from "./components/AdminHome";
import AdminDashboard from "./pages/AdminDashboard";
import AdminInventory from "./pages/AdminInventory";

import AdminRates from "./pages/AdminRates";
import Invoice from "./components/Invoice";
import CancelBooking from "./components/CancelBooking";
import { RoomProvider } from "./context/RoomContext";
import { RoomSelectionProvider } from "./context/RoomSelectionContext";
import { DashboardProvider } from "./context/DashboardContext";

const App = () => {
  return (
    <RoomProvider>
      <RoomSelectionProvider>
        <DashboardProvider>
          <Router>
            <ConditionalHeaders />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/restaurant" element={<Restaurant />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/room/:id" element={<RoomDetails />} />
              <Route path="/rooms" element={<Rooms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/customerdetails" element={<CustomerDetails />} />
              <Route path="/customerreviews" element={<CustomerReviews />} />
              <Route path="/paymentsuccess" element={<PaymentSuccess />} />
              <Route path="/login" element={<Login />} />
              <Route path="/adminheader" element={<AdminHeader />} />
              <Route path="/adminhome" element={<AdminHome />} />
              <Route path="/admindashboard" element={<AdminDashboard />} />
              <Route path="/admininventory" element={<AdminInventory />} />
              
              <Route path="/adminrates" element={<AdminRates />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/cancel-booking/:bookingId" element={<CancelBooking />} />
            </Routes>
            <ConditionalFooter />
          </Router>
        </DashboardProvider>
      </RoomSelectionProvider>
    </RoomProvider>
  );
};
const ConditionalHeaders = () => {
  const location = useLocation();
  const excludedPaths = [
    "/login",
    "/adminhome",
    "/admindashboard",
    "/adminpayments",
    
    "/adminrates",
    "/invoice",
    "/customerreviews",
   "/cancel-booking/:bookingId"
  ];

  if (excludedPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <>
      <PreHeader />
      {location.pathname === "/" ? <HomeHeader /> : <Header />}
    </>
  );
};

const ConditionalFooter = () => {
  const location = useLocation();
  const excludedPaths = [
    "/login",
    "/adminhome",
    "/admindashboard",
    "/adminpayments",
   
    "/adminrates",
    "/invoice",
    "/customerreviews",
     "/cancel-booking/:bookingId"
  ];

  if (excludedPaths.includes(location.pathname)) {
    return null;
  }

  return <Footer />;
};

export default App;
