


import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    dateFilter: "",
    bookingStatus: "pending",
    paymentStatus: "pending",
  });

  const navigate = useNavigate();
  const pageSize = 5; // Number of bookings per page

  // Wrapping fetchBookings in useCallback to avoid dependency warning
  const fetchBookings = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`http://localhost:5001/api/v1/bookings`, {
        params: {
          page,
          limit: pageSize,
          ...filters,
          searchQuery, // Include search query in the request
        },
      });
      const fetchedBookings = response.data;

      // Sort the fetched bookings based on searchQuery
      const sortedBookings = searchQuery
        ? fetchedBookings.sort((a, b) => {
            if (a.bookingId === searchQuery) return -1;
            if (b.bookingId === searchQuery) return 1;
            return new Date(b.checkInDate) - new Date(a.checkInDate);
          })
        : fetchedBookings.sort((a, b) => new Date(b.checkInDate) - new Date(a.checkInDate));

      setBookings((prevBookings) =>
        page === 1 ? sortedBookings : [...prevBookings, ...sortedBookings]
      );
      setHasMore(fetchedBookings.length === pageSize);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setLoading(false);
    }
  }, [page, filters, searchQuery]);

  useEffect(() => {
    fetchBookings();
  }, [filters, page, fetchBookings]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setPage(1); // Reset to the first page when filters change
  };

  const handleSearch = () => {
    setPage(1); // Reset page to 1 for new search results
    fetchBookings();
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleCheckIn = async (id, bookingId) => {
    try {
    await axios.patch(
        `http://localhost:5001/api/v1/bookings/update-status/${bookingId}`,
        {
          isCheckedIn: true,
          paymentStatus: "successful",
        }
      );

      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === id
            ? { ...booking, isCheckedIn: true, paymentStatus: "successful" }
            : booking
        )
      );
    } catch (error) {
      console.error("Error during check-in:", error);
    }
  };

  const handleCheckOut = async (id, bookingId) => {
    try {
       await axios.patch(
        `http://localhost:5001/api/v1/bookings/update-status/${bookingId}`,
        {
          isCheckedOut: true,
        }
      );

      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === id ? { ...booking, isCheckedOut: true } : booking
        )
      );
    } catch (error) {
      console.error("Error during check-out:", error);
    }
  };

  const navigateToInvoice = (booking) => {
    navigate("/invoice", { state: { booking } });
  };

  if (loading && page === 1) {
    return <div className="text-center text-gray-500">Loading bookings...</div>;
  }

  return (
    <div className="p-8 bg-gray-900 min-h-screen flex space-x-8 text-white">
      {/* Filters Section */}
      <div className="w-1/7 p-6 bg-gray-800 shadow-md rounded-lg">
        <h2 className="text-lg font-semibold mb-4 text-yellow-400">Filters</h2>
        <div className="mb-6">
          <h3 className="text-md font-medium mb-2 text-gray-300">Date Filters</h3>
          <label className="block mb-2">
            <input
              type="radio"
              name="dateFilter"
              value="checkIn"
              className="mr-2"
              checked={filters.dateFilter === "checkIn"}
              onChange={handleFilterChange}
            />
            <span className="text-gray-400">Check-in</span>
          </label>
          <label className="block mb-2">
            <input
              type="radio"
              name="dateFilter"
              value="checkOut"
              className="mr-2"
              checked={filters.dateFilter === "checkOut"}
              onChange={handleFilterChange}
            />
            <span className="text-gray-400">Check-out</span>
          </label>
        </div>

               {/* Payment Status */}
        <div>
          <h3 className="text-md font-medium mb-2 text-gray-300">Payment Status</h3>
          <label className="block mb-2">
            <input
              type="radio"
              name="paymentStatus"
              value="pending"
              className="mr-2"
              checked={filters.paymentStatus === "pending"}
              onChange={handleFilterChange}
            />
            <span className="text-gray-400">Pending</span>
          </label>
          <label className="block mb-2">
            <input
              type="radio"
              name="paymentStatus"
              value="processed"
              className="mr-2"
              checked={filters.paymentStatus === "processed"}
              onChange={handleFilterChange}
            />
            <span className="text-gray-400">Processed</span>
          </label>
        </div>
      </div>

      {/* Bookings Section */}
      <div className="w-3/2 bg-gray-800 shadow-lg rounded-lg">
        <div className="p-6 border-b border-gray-700 flex justify-between items-center">
          <h2 className="text-2xl font-semibold text-yellow-400">Bookings</h2>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              placeholder="Search by booking ID or guest"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border text-black border-gray-600 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <button
              onClick={handleSearch}
              className="bg-yellow-500 text-black px-4 py-2 rounded-md hover:bg-yellow-600"
            >
              Search
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-7 gap-2 font-semibold text-gray-400 border-b border-gray-600 pb-2 mb-4">
            <div>Guest Name</div>
            <div>Stay Duration</div>
            <div>Room & Meal Plan</div>
            <div>Booking ID</div>
            <div>Guest Contact</div>
            <div className="text-left">Net Amount</div>
          </div>

          <div className="space-y-4">
  {bookings.map((booking) => (
    <div
      key={booking.id}
      className="grid grid-cols-7 gap-4 items-center p-4 border border-gray-600 rounded-lg bg-gray-700 shadow-sm"
    >
      <div className="flex flex-col">
        <span className="font-semibold text-yellow-400">{booking.customerName}</span>
      </div>
      <div className="flex flex-col text-gray-400">
        <span>Check-In: {new Date(booking.checkInDate).toLocaleDateString()}</span>
        <span>Check-Out: {new Date(booking.checkOutDate).toLocaleDateString()}</span>
      </div>
      <div className="text-gray-400">
        <span>{booking.roomType}</span>
        <div className="text-sm text-gray-500">MealPlan: {booking.mealPlan}</div>
      </div>
      <div className="text-gray-400">
        <span>{booking.bookingId || "N/A"}</span>
      </div>
      <div className="text-gray-400">
        <span>{booking.phoneNumber}</span>
      </div>
      <div className="flex flex-col justify-between items-start">
        <span className="text-lg font-semibold text-yellow-400">
          ₹{booking.amount || "N/A"}
        </span>
        <span
          className={`text-sm font-semibold ${
            booking.status === "Cancelled" ? "text-white-500" : "text-gray-500"
          }`}
        >
          {booking.status === "Cancelled" ? "Cancelled" : booking.paymentStatus}
        </span>
      </div>
      <div className="flex space-x-3 -ml-10">
        {booking.status === "Cancelled" ? (
          <span className="text-white-500 font-semibold text-sm">Cancelled</span>
        ) : (
          <>
            <button
              className="bg-purple-500 text-white px-3 py-1 rounded-md hover:bg-purple-600 text-sm"
              onClick={() => navigateToInvoice(booking)}
            >
              Invoice
            </button>
            {!booking.isCheckedIn && (
              <button
                onClick={() => handleCheckIn(booking.id, booking.bookingId)}
                className="bg-green-500 text-white px-3 py-1 rounded-md hover:opacity-90 text-sm"
              >
                Check-In
              </button>
            )}
            {booking.isCheckedIn && !booking.isCheckedOut && (
              <button
                onClick={() => handleCheckOut(booking.id, booking.bookingId)}
                className="bg-blue-500 text-white px-3 py-1 rounded-md hover:opacity-90 text-sm"
              >
                Check-Out
              </button>
            )}
          </>
        )}
      </div>
    </div>
  ))}
</div>


          {hasMore && (
            <div className="flex justify-end mt-6">
              <button
                onClick={loadMore}
                className="bg-yellow-500 text-black px-4 py-2 rounded-md hover:bg-yellow-600"
              >
                See More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bookings;

