


import React, { useState, useContext } from 'react';
import CheckIn from './CheckIn'; // Ensure the path is correct
import CheckOut from './CheckOut'; // Ensure the path is correct
import AdultsDropdown from './AdultsDropdown'; // Ensure the path is correct
import KidsDropdown from './KidsDropdown'; // Ensure the path is correct
import axios from 'axios';
import { RoomContext } from '../context/RoomContext'; // Import RoomContext correctly
import { SpinnerDotted } from 'spinners-react'; // Import spinner component

const BookForm = () => {
  const { setRooms, setSelectedRoomDetails } = useContext(RoomContext); // Access setRooms and setSelectedRoomDetails from RoomContext
  const [checkIn, setCheckIn] = useState(new Date()); // Check-in date state
  const [checkOut, setCheckOut] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow; // Default check-out date to one day after check-in
  });
  const [loading, setLoading] = useState(false); // Loading state for API requests
  const [adults, setAdults] = useState(1); // Adults count
  const [kids, setKids] = useState(0); // Kids count

  // Fetch available rooms for the selected date range
  const fetchAvailableRooms = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading animation

    try {
      const response = await axios.get('http://localhost:5001/v1/api/inventory/available', {
        params: {
          checkIn: checkIn.toISOString().split('T')[0],
          checkOut: checkOut.toISOString().split('T')[0],
        },
      });

      if (response.data && response.data.length > 0) {
        setRooms(response.data);
        setSelectedRoomDetails((prevDetails) => ({
          ...prevDetails,
          checkInDate: checkIn.toISOString().split('T')[0],
          checkOutDate: checkOut.toISOString().split('T')[0],
          adults,
          kids,
          numberOfDays: Math.ceil((checkOut - checkIn) / (1000 * 3600 * 24)),
        }));
      } else {
        setRooms([]); // Handle no available rooms
      }
    } catch (error) {
      console.error('Error fetching available rooms:', error);
    } finally {
      setTimeout(() => {
        setLoading(false); // Stop loading animation after 2 seconds for smooth UX
      }, 2000);
    }
  };

  return (
    <div className="relative w-full p-4 bg-white shadow-lg rounded-lg">
      {/* Loading Spinner */}
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
          <SpinnerDotted size={70} color="white" />
        </div>
      )}

      <form onSubmit={fetchAvailableRooms} className="relative">
        <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:gap-3">
          {/* Check-In Date Picker */}
          <div className="flex-1 flex items-center border border-gray-300 rounded-lg p-3 bg-gradient-to-r from-gray-50 to-gray-100 shadow-sm">
            <CheckIn selectedDate={checkIn} onChangeDate={setCheckIn} />
          </div>

          {/* Check-Out Date Picker */}
          <div className="flex-1 flex items-center border border-gray-300 rounded-lg p-3 bg-gradient-to-r from-gray-50 to-gray-100 shadow-sm">
            <CheckOut selectedDate={checkOut} onChangeDate={setCheckOut} />
          </div>

          {/* Adults Dropdown */}
          <div className="flex-1 flex items-center border border-gray-300 rounded-lg p-3 bg-gradient-to-r from-gray-50 to-gray-100 shadow-sm">
            <AdultsDropdown selected={adults} onChange={(value) => setAdults(value)} />
          </div>

          {/* Kids Dropdown */}
          <div className="flex-1 flex items-center border border-gray-300 rounded-lg p-3 bg-gradient-to-r from-gray-50 to-gray-100 shadow-sm">
            <KidsDropdown selected={kids} onChange={(value) => setKids(value)} />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full lg:w-auto ${
              loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-yellow-500 to-yellow-600'
            } text-white font-bold py-3 px-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-300 transform hover:scale-105`}
            disabled={loading} // Disable button during loading
          >
            {loading ? 'Loading...' : 'Check Now'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BookForm;


