

import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { format, addDays } from 'date-fns';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);

  const calendarRef = useRef();

  // Generate Week Dates Starting from Selected Date
  const getWeekDates = useCallback(() => {
    return Array.from({ length: 7 }, (_, i) => addDays(selectedDate, i));
  }, [selectedDate]);

  // Fetch Inventory Data
  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const weekDates = getWeekDates();
        const startDate = format(weekDates[0], 'yyyy-MM-dd');
        const endDate = format(weekDates[weekDates.length - 1], 'yyyy-MM-dd');

        const response = await axios.get('http://localhost:5001/v1/api/inventory', {
          params: { startDate, endDate },
        });

        setInventory(response.data);
      } catch (error) {
        console.error('Error fetching inventory:', error);
      }
    };

    fetchInventory();
  }, [getWeekDates]);

  // Handle Inventory Update
  const updateInventory = async (id, roomId, date, availableRooms) => {
    if (isNaN(availableRooms) || availableRooms < 0) {
      console.error('Invalid availableRooms value');
      return;
    }

    try {
      await axios.put('http://localhost:5001/v1/api/inventory', {
        id,
        room_id: roomId,
        date,
        available_rooms: availableRooms,
      });

      setInventory((prevInventory) =>
        prevInventory.map((item) =>
          item.id === id ? { ...item, available_rooms: availableRooms } : item
        )
      );
    } catch (error) {
      console.error('Error updating inventory:', error);
    }
  };

  // Handle Date Selection
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setCalendarOpen(false);
  };

  // Close Calendar on Outside Click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalendarOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Group Inventory by Room ID
  const groupByRoomId = () => {
    return inventory.reduce((acc, item) => {
      acc[item.room_id] = acc[item.room_id] || [];
      acc[item.room_id].push(item);
      return acc;
    }, {});
  };

  // Render Inventory Table
  const renderTable = () => {
    const groupedInventory = groupByRoomId(); // Groups data by room_id
    const weekDates = getWeekDates();

    return (
      <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
        <table className="min-w-full bg-gradient-to-r from-gray-100 to-gray-50 border">
          <thead>
            <tr className="bg-gradient-to-r from-gray-200 to-gray-300 text-gray-800">
              <th className="p-4 text-sm font-bold border-b">Room ID</th>
              {weekDates.map((date) => (
                <th key={date} className="text-center p-4 text-sm font-bold border-b">
                  {format(date, 'EEE dd MMM')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedInventory).map(([roomId, items]) => (
              <tr key={roomId} className="bg-gray-50 hover:bg-gray-100">
                <td className="p-4 text-sm text-gray-600 font-semibold border-b">{roomId}</td>
                {weekDates.map((date) => {
                  const inventoryForDate = items.find(
                    (item) =>
                      format(new Date(item.date), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
                  );
                  return (
                    <td key={date} className="text-center p-4 border-b">
                      {inventoryForDate ? (
                        <input
                          type="number"
                          value={inventoryForDate.available_rooms}
                          onChange={(e) =>
                            updateInventory(
                              inventoryForDate.id,
                              roomId,
                              format(date, 'yyyy-MM-dd'),
                              parseInt(e.target.value, 10) || 0 // Default to 0 if invalid input
                            )
                          }
                          className="w-16 text-center border border-gray-300 rounded-md shadow-sm"
                          min="0"
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="p-6 bg-gradient-to-r from-gray-50 to-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Inventory Management</h1>
        <div className="relative">
          <div className="flex items-center space-x-2">
            <span className="text-xl font-extrabold text-gray-600">Bulk Editor</span>
            <button
              onClick={() => setCalendarOpen((prev) => !prev)}
              className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow flex items-center justify-center"
              title="Select Date"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7v1m8-1v1m-9 4h10m4 6V7a2 2 0 00-2-2h-2V3m-8 2H6a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2z"
                />
              </svg>
            </button>
          </div>
          {calendarOpen && (
            <div
              ref={calendarRef}
              className="absolute top-full left-0 bg-white shadow-lg border mt-2 p-4 rounded-lg z-50"
            >
              <input
                type="date"
                className="border border-gray-300 p-2 rounded w-full"
                value={format(selectedDate, 'yyyy-MM-dd')}
                onChange={(e) => handleDateSelect(new Date(e.target.value))}
              />
            </div>
          )}
        </div>
      </div>
      {renderTable()}
    </div>
  );
};

export default Inventory;




