import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { EffectFade, Autoplay } from 'swiper';
import Img1 from '../assets/img/heroSlider/1.png';
import Img2 from '../assets/img/heroSlider/1.png';
import Img3 from '../assets/img/heroSlider/1.png';

const slides = [
  {
    title: 'For Luxury Accommodation',
    bg: Img1,
  },
  {
    title: 'For Luxury Accommodation',
    bg: Img2,
  },
  {
    title: 'For Luxury Accommodation',
    bg: Img3,
  },
];

const HeroSlider = () => {
  
  return (
    <div id='home'>
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect={'fade'}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className='heroSlider h-[600px] lg:h-[860px] mb-0' // Removed bottom margin
      >
        {slides.map((slide, index) => {
          const { title, bg } = slide;
          return (
            <SwiperSlide
              className='h-full relative flex justify-center items-center'
              key={index}
            >
              <div className='z-20 text-white text-center'>
                <div className='uppercase font-tertiary tracking-[6px] mb-5 opacity-100'>
                  Welcome to Elite Hotel by Hiwa
                </div>
                <h1
                  className='text-[24px] lg:text-[48px] font-primary uppercase tracking-[2px] max-w-[920px] leading-tight mb-6 text-shadow opacity-100'
                >
                  {title}
                </h1>
                
              </div>

              {/* Image background with brightness filter */}
              <div className='absolute top-0 w-full h-full'>
                <img
                  className='object-cover h-full w-full brightness-110' // Slightly increase brightness
                  src={bg}
                  alt='Slide Background'
                />
              </div>

              {/* Overlay with reduced opacity for clearer images */}
              <div className='absolute w-full h-full bg-black/30'></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
