// Reviews.js
import React, { useState, useEffect } from "react";  
import axios from "axios";  
import ScrollToTop from "../components/ScrollToTop";  

const ReviewText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false); // Track if the review is expanded

  // Split the review text into words
  const words = text.split(" ");
  const truncatedText = words.slice(0, 30).join(" ");

  // Toggle function to switch between expanded and truncated text
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-3 text-gray-800">
      {/* Display truncated or full text based on the `isExpanded` state */}
      <p>{isExpanded ? text : truncatedText}</p>

      {/* Only show the button if the review text is longer than 100 words */}
      {words.length > 30 && (
        <button
          onClick={toggleExpand}
          className="text-blue-500 hover:underline mt-2"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

const Reviews = () => {  
  const [reviews, setReviews] = useState([]); // State to hold the reviews  
  const [lastTenRatings, setLastTenRatings] = useState([]);  
  const [visibleReviews, setVisibleReviews] = useState(4);  

  useEffect(() => {  
      
    const fetchReviews = async () => {  
      try {  
        const response = await axios.get("http://localhost:5001/api/v1/reviews");  
        console.log(response.data);   
        setReviews(response.data);   
        
        const ratings = response.data.map(review => review.rating).slice(0, 10);  
        setLastTenRatings(ratings); 
      } catch (error) {  
        console.error("Error fetching reviews:", error);  
      }  
    };  

    fetchReviews();  
  }, []);  

  const loadMoreReviews = () => {  
    setVisibleReviews(prevVisibleReviews => prevVisibleReviews + 4); // Show 4 more reviews  
  };  

  return (  
    <article>  
      <ScrollToTop />  
      <section className="container mx-auto px-4 md:px-0 pt-32 py-12">  
        <h2 className="text-2xl md:text-3xl font-bold mb-4">User Rating & Reviews</h2>  

        {/* Tabs for All, Couple, Family, Business, etc. */}  
        <div className="flex space-x-4 text-lg mb-4 border-b-2 pb-2">  
          {["All", "Couple", "Family", "Business", "Group", "Solo"].map((category) => (  
            <button  
              key={category}  
              className="font-bold text-blue-500 border-b-2 border-blue-500 pb-2 focus:outline-none"  
            >  
              {category}  
            </button>  
          ))}  
        </div>  
                              
        {/* Overall Rating Summary */}  
        <div className="flex flex-col md:flex-row">  
          <div className="w-full md:w-1/4 mb-4 md:mb-0">  
            <div className="flex items-center mb-4">  
              <div className="flex items-center justify-center w-20 h-20 bg-blue-100 rounded-full mr-4">  
                <p className="text-2xl font-bold text-blue-500">3.9</p>  
              </div>  
              <div>  
                <p className="text-blue-700 font-bold text-xl">Very Good</p>  
              </div>  
            </div>  

            {/* Ratings Breakdown */}  
            <table className="w-full">  
              <tbody>  
                {[  
                  { label: "Excellent", percentage: 53 },  
                  { label: "Very Good", percentage: 14 },  
                  { label: "Average", percentage: 12 },  
                  { label: "Poor", percentage: 11 },  
                  { label: "Bad", percentage: 10 },  
                ].map((rating, index) => (  
                  <tr key={index} className="items-center mb-2">  
                    <td className="w-1/5 text-right pr-4">{rating.label}</td>  
                    <td className="w-3/5">  
                      <div className="h-2 bg-gray-200">  
                        <div className="h-2 bg-blue-500" style={{ width: `${rating.percentage}%` }}></div>  
                      </div>  
                    </td>  
                    <td className="w-1/5 text-left pl-4">{rating.percentage}%</td>  
                  </tr>  
                ))}  
              </tbody>  
            </table>  

            {/* Recent Ratings */}  
            <p className="mt-4 font-bold">Last 10 Customer Ratings (Latest First)</p>  
            <div className="flex space-x-1 mt-2">  
              {lastTenRatings.length > 0 ? (  
                lastTenRatings.map((rating, index) => (  
                  <div  
                    key={index}  
                    className="flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full text-blue-500 font-bold"  
                  >  
                    {rating}  
                  </div>  
                ))  
              ) : (  
                <p>No ratings yet</p>  
              )}  
            </div>  

            {/* Rating Categories */}  
            <div className="mt-8">  
              <h3 className="font-bold text-lg">Rating Categories</h3>  
              <table className="mt-4 w-full">  
                <tbody>  
                  {[  
                    { category: "Location", rating: 4.4 },  
                    { category: "Cleanliness", rating: 3.8 },  
                    { category: "Room", rating: 4.1 },  
                    { category: "Food", rating: 4.0 },  
                    { category: "Value for Money", rating: 4.0 },  
                    { category: "Hospitality", rating: 5.0 },  
                  ].map((item, index) => (  
                    <tr key={index} className="flex justify-between">  
                      <td>{item.category}</td>  
                      <td className="bg-blue-100 text-blue-500 px-2 py-1 rounded-full text-center">  
                        {item.rating}  
                      </td>  
                    </tr>  
                  ))}  
                </tbody>  
              </table>  
            </div>  
          </div>  

          {/* Traveler Impressions and Reviews */}  
          <div className="w-full md:w-3/4 pl-0 md:pl-6">  
            <div className="mt-4">  
              <h3 className="font-bold text-lg">Traveller Impressions</h3>  
              <p className="text-gray-700 mt-2">  
                The property is located near the airport and has clean and spacious rooms. Guests have appreciated the  
                peaceful nature and beautiful views from the property. The food served at the restaurant is tasty and  
                hygienic. The staff is polite, helpful, and professional. The check-in process is smooth and easy.  
                However, some guests have complained about maintenance issues like non-functional AC, dirty bed sheets  
                and towels, and lack of toiletries. Overall, the property is a good option for a comfortable stay near  
                the airport.  
              </p>  
            </div>  

            {/* Customer Reviews */}  
            <div className="mt-8">  
                <h3 className="font-bold text-lg">Customer Reviews</h3>  
            {reviews.length === 0 ? (  
             <p>No reviews yet.</p>  
              ) : (  
                reviews.slice(0, visibleReviews).map((review, index) => (  
                   <div key={index} className="border-t pt-4 mt-4 pb-4">  
             {/* Display Customer Name */}
             <div className="flex justify-between items-center mb-4">
          <p className="font-bold text-gray-800">{review.customerName || "Anonymous"}</p>
          <span className="text-2xl font-bold text-gray-700">{review.rating}/5</span>
        </div>
        
        {/* Review content with Read More/Less functionality */}
        <ReviewText text={review.text} />
        
        {/* Display images if available */}  
        {review.images && review.images.length > 0 && (
          <div className="mt-4 flex flex-wrap space-x-4">
            {review.images.map((image, imgIndex) => (
              <img
                key={imgIndex}
                src={`http://localhost:5001/uploads/${image}`}
                alt={`Customer review ${index} - ${imgIndex + 1}`} // Updated alt text
                className="w-[100px] h-[100px] sm:w-[75px] sm:h-[75px] md:w-[100px] md:h-[100px] object-cover mt-3 rounded shadow"
              />
            ))}
          </div>
        )}
      </div>
    ))  
  )}  
</div>  

            {visibleReviews < reviews.length && (  
              <button onClick={loadMoreReviews} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">  
                Load More Reviews  
              </button>  
            )}  
          </div>  
        </div>  
      </section>  
    </article>  
  );  
};  

export default Reviews;
