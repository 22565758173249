


import React, { useState, useEffect } from 'react';
import { FaInstagram, FaFacebook, FaYoutube,  FaEnvelope,  } from 'react-icons/fa';

const PreHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollY = currentScrollY;

      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`bg-red-900 text-white py-2 px-4 sm:px-6 lg:px-12 transition-transform duration-300 ${
        isScrolled ? 'fixed top-0 left-0 right-0 z-50' : ''
      } ${scrollDirection === 'up' ? 'translate-y-0' : '-translate-y-full'}`}
    >
      <div className="container mx-auto flex items-center justify-between flex-wrap lg:flex-nowrap">
        {/* Left Side: Contact Number and Email */}
        <div className="flex items-center text-sm sm:text-base lg:text-lg space-x-4">
          <span className="font-semibold">+91 6309387321</span>
          <span>|</span>
          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-base sm:text-lg lg:text-xl" />
            <a
              href="mailto:info.hiwahotels@gmail.com"
              className="text-white hover:text-gray-400 font-medium"
            >
              info.hiwahotels@gmail.com
            </a>
          </div>
        </div>

        {/* Right Side: Social Media Icons */}
        <div className="flex items-center space-x-3 lg:space-x-4 mt-2 lg:mt-0">
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-400"
            aria-label="Instagram"
          >
            <FaInstagram className="text-lg sm:text-xl lg:text-2xl" />
          </a>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-400"
            aria-label="Facebook"
          >
            <FaFacebook className="text-lg sm:text-xl lg:text-2xl" />
          </a>
          <a
            href="https://www.youtube.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-400"
            aria-label="YouTube"
          >
            <FaYoutube className="text-lg sm:text-xl lg:text-2xl" />
          </a>
          
        </div>
      </div>
    </div>
  );
};

export default PreHeader;

