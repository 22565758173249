import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { useNavigate } from 'react-router-dom'; // For redirection
import hotelLogo from '../assets/img/elite-logo.png';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for Date Picker
  const [isProfileOpen, setIsProfileOpen] = useState(false); // Modal state for Profile
  const [date, setDate] = useState(new Date()); // Selected date state
  const navigate = useNavigate(); // For navigating after logout
  const profileRef = useRef(null); // Reference for profile modal

  // Toggle modal visibility for DatePicker
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Toggle profile modal visibility
  const toggleProfileModal = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  // Logout function
  const handleLogout = () => {
    // Perform any logout operations here (clear tokens, etc.)
    navigate("/"); // Redirect to login page
  };

  // Close profile modal on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false); // Close the profile modal
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  return (
    <div className="flex-1 top-0 left-0 w-full p-8 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-900 text-white  shadow-lg z-50 rounded-3xl">
      {/* Left Side: Reservation Calendar */}
      <div className="flex items-center justify-between">
        <div className="relative">
          {/* Reservation Calendar Button */}
          <div
            className="flex items-center space-x-2 cursor-pointer hover:scale-105 transform transition duration-300 ease-in-out"
            onClick={toggleModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7V3m8 4V3m-9 8h10m-6 4h6m2 4H5a2 2 0 01-2-2V7a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2z"
              />
            </svg>
            <h2 className="text-xl font-bold text-gray-100">
              Reservation Calendar
            </h2>
          </div>

          {/* Modal for Calendar */}
          {isModalOpen && (
            <div className="absolute top-12 left-0 bg-gradient-to-b from-blue-900 via-gray-800 to-gray-900 text-white rounded-lg shadow-2xl p-6 w-72 transform scale-105 transition-all duration-300 ease-in-out z-50">
              <h3 className="text-gray-300 text-lg font-bold mb-4">
                Select Date
              </h3>
              <DatePicker
                onChange={setDate}
                value={date}
                className="w-full text-white"
                clearIcon={null}
                calendarIcon={null}
                format="dd/MM/y"
                calendarClassName="bg-gray-800 text-white rounded-lg shadow-xl calendar-solid-bg" // Custom class for calendar popup
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              />
              <div className="flex justify-end mt-4">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Right Side: Search Box and Profile */}
        <div className="flex items-center space-x-4">
          {/* Search Box */}
          

          {/* Profile Section */}
          <div className="relative" ref={profileRef}>
            {/* Profile Icon with Elite Label */}
            <div
              className="flex items-center cursor-pointer space-x-2"
              onClick={toggleProfileModal}
            >
              <img
                src={hotelLogo} // Replace with actual profile image URL
                alt="Profile"
                className="w-14 h-14 rounded-full"
              />
              <span className="text-lg font-bold text-gray-300">Elite</span>
            </div>

            {/* Modal for Profile */}
            {isProfileOpen && (
              <div className="absolute top-12 right-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-700 text-white rounded-lg shadow-2xl p-6 w-56 transform scale-105 transition-all duration-300 ease-in-out z-50">
                <h3 className="text-gray-300 text-lg font-bold mb-4">
                  Elite Hotel By Hiwa
                </h3>
                <button
                  className="w-full py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
