import React, { useState, useEffect } from "react";
import LogoOak from "../assets/img/elite-logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHeaderScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeMenu();
  };

  return (
    <header
      className={`${
        headerScrolled ? "py-1 shadow-lg bg-black" : "py-3 lg:py-2 bg-black"
      } fixed z-50 w-full transition-all duration-500`}
      style={{
        backgroundImage: `url(${require("../assets/img/footer2.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mx-auto flex items-center justify-between px-6 sm:px-4 lg:px-12">
        {/* Logo */}
        <div className="flex items-center cursor-pointer">
          <img
            src={LogoOak}
            className="w-12 md:w-16 lg:w-20"
            alt="Elite Hotel Logo"
            onClick={() => handleNavigation("/")}
          />
        </div>

        {/* Hamburger Icon */}
        <div className="lg:hidden z-50 absolute top-4 right-4" onClick={toggleMenu}>
            {menuOpen ? (
           <FaTimes className="text-3xl text-white cursor-pointer" />
              ) : (
          <FaBars className="text-3xl text-white cursor-pointer" />
         )}
       </div>


        {/* Full Navigation (Visible on large screens) */}
        <nav className="hidden lg:flex items-center gap-8 text-white">
          <NavItem text="Home" onClick={() => handleNavigation("/")} />
          <NavItem text="About" onClick={() => handleNavigation("/about")} />
          <NavItem text="Rooms" onClick={() => handleNavigation("/rooms")} />
          <NavItem
            text="Restaurant"
            onClick={() => handleNavigation("/restaurant")}
          />
          <NavItem text="Gallery" onClick={() => handleNavigation("/gallery")} />
          <NavItem text="Contact" onClick={() => handleNavigation("/contact")} />
          <NavItem text="Reviews" onClick={() => handleNavigation("/reviews")} />
          <button
            onClick={() => handleNavigation("/rooms")}
            className=" text-white-700  py-2 px-6 rounded-full hover:bg-red-600 transition duration-300"
            style={{
              backgroundColor: "#FFD700", // Pure golden color
            }}
             
          >
            Book Now
          </button>
        </nav>

        {/* Navigation Menu for Mobile Devices */}
        <nav
          className={`fixed top-0 left-0 w-full bg-black bg-opacity-90 text-white transition-transform duration-500 ease-in-out ${
            menuOpen ? "transform translate-y-0" : "transform -translate-y-full"
          } lg:hidden`}
        >
          <div className="relative flex flex-col items-center p-8 space-y-6">
            {/* Header for Menu */}
            <div
              className="text-2xl font-bold cursor-pointer"
              onClick={() => handleNavigation("/")}
            >
              Elite Hotel
            </div>

            {/* Navigation Links for Mobile */}
            <NavItem text="Home" onClick={() => handleNavigation("/")} />
            <NavItem text="About" onClick={() => handleNavigation("/about")} />
            <NavItem text="Rooms" onClick={() => handleNavigation("/rooms")} />
            <NavItem
              text="Restaurant"
              onClick={() => handleNavigation("/restaurant")}
            />
            <NavItem text="Gallery" onClick={() => handleNavigation("/gallery")} />
            <NavItem text="Contact" onClick={() => handleNavigation("/contact")} />
            <NavItem text="Reviews" onClick={() => handleNavigation("/reviews")} />

            {/* Book Now Button */}
            <button
              onClick={() => handleNavigation("/rooms")}
              className="mt-4 bg-red-500 text-white py-2 px-6 rounded-full hover:bg-red-600 transition duration-300"
            >
              Book Now
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

const NavItem = ({ text, onClick }) => (
  <div
    className="text-lg font-medium cursor-pointer hover:text-red-500 transition duration-300"
    onClick={onClick}
  >
    {text}
  </div>
);

export default Header;
