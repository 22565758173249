import React, { useContext } from 'react';
import { RoomContext } from '../context/RoomContext';
import Room from './Room';
import { SpinnerDotted } from 'spinners-react';
import BookForm from './BookForm';

const Rooms = () => {
  const { rooms, loading } = useContext(RoomContext);

  return (
    <section className='py-20'>
      {/* Loading spinner */}
      {loading && (
        <div className='h-screen fixed bottom-0 top-0 bg-black/90 w-full z-50 flex justify-center items-center'>
          <SpinnerDotted color='white' />
        </div>
      )}

      <div className='container mx-auto lg:px-0'>
        {/* Page header */}
        <div className='flex flex-col items-center'>
          <div className='text-center mb-6'>
            <div className='font-tertiary uppercase text-[25px] tracking-[6px]'>
              Hotel & Restaurant
            </div>
            <h2 className='font-primary text-[45px]'>Room & Suites</h2>
          </div>

          {/* Booking Form */}
          <div className='w-full max-w-4xl'>
            <BookForm />
          </div>
        </div>

        {/* Display available rooms */}
        <div className='grid grid-cols-1 max-w-sm mx-auto gap-[30px] lg:grid-cols-3 lg:max-w-none lg:mx-0 mt-8'>
          {rooms.length > 0 ? (
            rooms.map((room) => <Room room={room} key={room.id} />)
          ) : (
            <p className="text-center text-gray-500">
              No rooms available for the selected dates. Please try different dates or check back later.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Rooms;


