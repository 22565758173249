// CustomerReviews.js
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios"; 
import { FaStar } from "react-icons/fa";

const CustomerReviews = () => {
  const [newReview, setNewReview] = useState({
    rating: 0,
    comment: "",
    images: [],
  });
  const [bookingId, setBookingId] = useState("");
  const [searchParams] = useSearchParams();

  // Fetch the bookingId from the URL query parameters
  useEffect(() => {
    const idFromQuery = searchParams.get("bookingId");
    if (idFromQuery) {
      setBookingId(idFromQuery);
    }
  }, [searchParams]);

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleImageChange = (e) => {
    setNewReview({ ...newReview, images: Array.from(e.target.files) });
  };

  const handleRatingChange = (rating) => {
    setNewReview({ ...newReview, rating });
  };

  const handleAddReview = async () => {
    if (!newReview.comment || !newReview.rating || !bookingId) {
      console.error("Comment, rating, and bookingId are required");
      return;
    }

    const formData = new FormData();
    formData.append("text", newReview.comment);
    formData.append("rating", newReview.rating);
    formData.append("bookingId", bookingId); // Attach bookingId to the review
    if (newReview.images.length > 0) {
      newReview.images.forEach((image) => {
        formData.append("images", image);
      });
    }

    try {
      const response = await axios.post(
        "http://localhost:5001/api/v1/reviews",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      
      console.log("Review submitted:", response.data);

      // Clear the form after successful submission
      setNewReview({ rating: 0, comment: "", images: [] });
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  return (
    <section className="py-12 flex flex-col items-center">
      <div className="w-full max-w-md p-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Leave Your Review</h2>
        <textarea
          name="comment"
          value={newReview.comment}
          onChange={handleReviewChange}
          placeholder="Write your review here"
          className="w-full mb-4 px-4 py-2 border rounded"
          rows="4"
        />
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <FaStar
              key={star}
              className={`cursor-pointer ${newReview.rating >= star ? "text-blue-500" : "text-gray-400"}`}
              onClick={() => handleRatingChange(star)}
            />
          ))}
        </div>
        <input
          type="file"
          name="images[]"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          className="w-full mb-4 px-4 py-2 border rounded"
        />
        <button
          onClick={handleAddReview}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded"
        >
          Submit Review
        </button>
      </div>
    </section>
  );
};

export default CustomerReviews;






