


import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar } from 'react-icons/bs';

const CheckOut = ({ selectedDate, onChangeDate }) => {
  return (
    <div className="flex items-center space-x-2">
      <BsCalendar className="text-yellow-500 text-lg" />
      <DatePicker
        id="checkOut"
        className="bg-transparent w-full text-gray-800 text-sm font-semibold focus:outline-none"
        selected={selectedDate}
        onChange={onChangeDate} // Call parent handler
        dateFormat="EEE dd MMM"
      />
    </div>
  );
};

export default CheckOut;
