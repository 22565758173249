import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import logo from "../assets/img/elite-logo.png"; // Adjust path as needed

const Invoice = () => {
  const location = useLocation();
  const booking = location.state?.booking;

  const gstRate = 0.12; // 12% GST rate
  const amount = parseFloat(booking?.amount.replace("₹", "") || 0);
  const gst = (amount * gstRate) / (1 + gstRate); // Extract GST included in Room Tariff
  const tariffWithoutGst = amount - gst;

  const navigate = useNavigate(); // For navigation to adminhome
  const invoiceRef = useRef(null);

  // Print function using useReactToPrint
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `Invoice_${booking.bookingId || "N/A"}`,
    onAfterPrint: () => navigate("/adminhome"), // Navigate after printing
  });

  useEffect(() => {
    // Automatically open the invoice in a new window for printing
    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>Invoice - ${booking.bookingId}</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.0.0/dist/tailwind.min.css">
          </head>
          <body>
            <div class="max-w-2xl mx-auto p-8 bg-white border border-gray-200 shadow-lg rounded-lg">
              <div class="text-center mb-6">
                <img src="${logo}" alt="Logo" class="h-16 mx-auto mb-2" />
                <h1 class="text-2xl font-bold">SIGNATURE HOTELS & RESORTS</h1>
                <p class="text-lg font-medium text-gray-600">Hotel Elite by Signature Group</p>
                <p class="text-sm">Address: 11-28/21, Airport Road, Shamshabad, Hyderabad - 501218</p>
                <p class="text-sm">Phone: 8121001084</p>
              </div>

              <div class="mb-4">
                <div class="flex justify-between text-sm">
                  <div><span class="font-bold">Invoice No:</span> ${booking.bookingId || "N/A"}</div>
                  <div><span class="font-bold">Room No:</span> ${booking.roomNumber || "N/A"}</div>
                </div>
                <div class="flex justify-between text-sm">
                  <div><span class="font-bold">Invoice Date:</span> ${new Date().toLocaleDateString()}</div>
                  <div><span class="font-bold">Booking Date:</span> ${
                    new Date(booking.checkInDate).toLocaleDateString() || "N/A"
                  }</div>
                </div>
              </div>

              <div class="mb-4">
                <p class="text-sm"><span class="font-bold">Guest Name:</span> ${
                  booking.customerName || "N/A"
                }</p>
                <p class="text-sm"><span class="font-bold">Contact:</span> ${
                  booking.phoneNumber || "N/A"
                }</p>
                <p class="text-sm"><span class="font-bold">Address:</span> ${
                  booking.address || "N/A"
                }</p>
              </div>

              <div class="mb-4">
                <p class="text-sm"><span class="font-bold">Check-In:</span> ${
                  new Date(booking.checkInDate).toLocaleDateString() || "N/A"
                }</p>
                <p class="text-sm"><span class="font-bold">Check-Out:</span> ${
                  new Date(booking.checkOutDate).toLocaleDateString() || "N/A"
                }</p>
                <p class="text-sm"><span class="font-bold">Room Type:</span> ${
                  booking.roomType || "N/A"
                }</p>
                <p class="text-sm"><span class="font-bold">Meal Plan:</span> ${
                  booking.mealPlan || "N/A"
                }</p>
              </div>

              <div class="border-t border-gray-200 mt-4 pt-4 text-sm">
                <div class="flex justify-between mb-2">
                  <div>Room Tariff (Including GST)</div>
                  <div>₹${amount.toFixed(2)}</div>
                </div>
                <div class="flex justify-between mb-2">
                  <div>Goods and Services Tax (12%)</div>
                  <div>₹${gst.toFixed(2)}</div>
                </div>
                <div class="flex justify-between mb-2">
                  <div>Tariff Without GST</div>
                  <div>₹${tariffWithoutGst.toFixed(2)}</div>
                </div>
              </div>

              <div class="border-t border-gray-200 mt-4 pt-4 text-lg font-bold text-right">
                <p>Total: ₹${amount.toFixed(2)}</p>
              </div>

              <div class="mt-6 text-center text-sm text-gray-600">
                <p>All payments are to be made by CASH/CARD/NEFT/UPI in the name of "SIGNATURE GROUP OF HOTELS"</p>
                <p>If you have any queries regarding this invoice, please contact us.</p>
                <p class="italic mt-2">We Will Do Our Best, Thank You For Your Business</p>
              </div>
            </div>
          </body>
        </html>
      `);

      newWindow.document.close();
      newWindow.print(); // Automatically trigger the print dialog
      newWindow.onafterprint = () => navigate("/adminhome"); // Navigate after printing
      newWindow.onbeforeunload = () => navigate("/adminhome"); // Navigate on window close
    }
  }, [booking, navigate, amount, gst]);

  return null; // Nothing is rendered in the current tab
};

export default Invoice;
