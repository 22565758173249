import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const CancelBooking = () => {
  const { bookingId } = useParams(); // Extract booking ID from the URL
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCancel = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `http://localhost:5001/api/v1/bookings/cancel/${bookingId}`
      );
      setStatus("success");
      console.log(response.data);
    } catch (error) {
      console.error("Error cancelling booking:", error);
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white px-4">
      <div className="max-w-md w-full bg-gray-900 rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-yellow-400 text-center mb-6">
          Cancel Your Booking
        </h1>
        {loading ? (
          <div className="flex justify-center">
            <div className="w-8 h-8 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : status === "success" ? (
          <div className="text-center">
            <p className="text-green-500 text-lg font-semibold mb-4">
              Your booking has been successfully cancelled.
            </p>
            <button
              onClick={() => navigate("/")}
              className="w-full bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-600"
            >
              Go Back to Home
            </button>
          </div>
        ) : status === "error" ? (
          <div className="text-center">
            <p className="text-red-500 text-lg font-semibold mb-4">
              An error occurred while cancelling your booking. Please try
              again.
            </p>
            <button
              onClick={() => navigate("/")}
              className="w-full bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-800"
            >
              Go Back to Home
            </button>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-gray-300 text-lg mb-6">
              Are you sure you want to cancel your booking? This action cannot
              be undone.
            </p>
            <div className="flex flex-col space-y-4">
              <button
                className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                onClick={handleCancel}
              >
                Confirm Cancellation
              </button>
              <button
                className="w-full bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-800"
                onClick={() => navigate("/")}
              >
                Go Back to Home
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelBooking;
