import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { EffectFade, Autoplay, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Img1 from "../assets/img/heroSlider/1.png";
import Img2 from "../assets/img/heroSlider/1.png";
import Img3 from "../assets/img/heroSlider/1.png";
import BackgroundImage from "../assets/img/bg.png";

const images = [Img1, Img2, Img3];

const AboutPage = () => {
  const navigate = useNavigate();

  const handleCheckNowClick = () => {
    navigate("/rooms");
  };

  return (
    <div className="relative overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></div>
      <div className="relative z-10">
        <Header />
        <div className="container mx-auto pt-20 lg:pt-32 pb-16 lg:pb-24">
          {/* Page Content */}
          <div className="flex flex-wrap lg:flex-nowrap items-center gap-12 lg:gap-16">
            {/* Swiper for Images */}
            <div className="w-full lg:w-1/2">
              <Swiper
                modules={[EffectFade, Autoplay, Navigation]}
                effect="fade"
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                className="rounded-lg shadow-lg overflow-hidden"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index} className="h-[300px] lg:h-[500px]">
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* About Content */}
            <div className="w-full lg:w-1/2 text-white bg-black/70 p-8 rounded-lg shadow-lg">
              <h3 className="text-4xl font-bold mb-6 text-yellow-400">
                About Elite by Signature Group
              </h3>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                Your Elegant Retreat Near the Airport
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Welcome to <span className="font-semibold text-yellow-400">
                  Elite by Signature Group
                </span>, where comfort meets elegance. Our hotel features a
                selection of <span className="font-semibold">45 beautifully appointed rooms</span>,
                including <span className="font-semibold">22 Signature Comfort</span>,{" "}
                <span className="font-semibold">17 Signature Superior</span>, and{" "}
                <span className="font-semibold">6 Signature Suites</span> —
                designed for ultimate relaxation and style.
              </p>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                The Ideal Location
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Located in the <span className="font-semibold text-yellow-400">
                  Shamshabad Airport Zone
                </span>, we offer a perfect blend of charm, tranquility, and
                accessibility. Ideal for both leisure and business travelers, our hotel serves as
                a gateway to explore the nearby{" "}
                <span className="font-semibold text-yellow-400">
                  Shamshabad Airport
                </span>{" "}
                and the vibrant city of{" "}
                <span className="font-semibold text-yellow-400">Hyderabad</span>.
              </p>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                Exceptional Service
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                At <span className="font-semibold text-yellow-400">
                  Elite by Signature Group
                </span>, our dedicated staff is committed to providing personalized
                and attentive service. Our goal is to ensure that each guest has
                a seamless and memorable experience during their stay.
              </p>
              <h4 className="text-2xl font-semibold mb-4 text-white">
                Amenities & Facilities
              </h4>
              <p className="text-lg mb-6 leading-relaxed text-gray-300">
                Spread across five floors, our hotel features a modern lift for easy access. Enjoy
                our inviting lounge, where you can unwind with drinks, snacks, or
                a good book. Don't miss our splendid terrace, perfect for
                relaxing in the lively atmosphere of daily life from morning till
                evening.
              </p>
              {/* Button */}
              <div className="flex justify-end">
                <button
                  onClick={handleCheckNowClick}
                  className="bg-yellow-500 hover:bg-yellow-600 text-black font-semibold px-8 py-4 rounded-lg shadow-lg transition-all duration-300 transform hover:scale-105"
                >
                  Check Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
